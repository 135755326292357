import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import dynamic from 'next/dynamic';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';
import { FormElementProvider } from 'src/contexts/form-element-context';
import { MessagesContextProvider } from 'src/contexts/messages-context';
import { UploadFormElementContextProvider } from 'src/contexts/upload-form-element-context';
import { useActiveLoan } from 'src/hooks/use-active-loan';
import { useLicenseKeys } from 'src/hooks/use-license-keys';
import { useOpenDrawer } from 'src/hooks/use-open-drawer';
import { useQueryParamToast } from 'src/hooks/useQueryParamToast';
import { SOCKET_URL } from 'src/lib/Socket';
import { DndProvider } from 'src/providers/dnd-provider';
import { fetch3rdPartyLicenseKeys } from 'src/slices/license-keys';
import { getJavascriptConstants } from 'src/slices/lists';
import { selectConnectionError, selectDrawerOpen, toggleDrawer } from 'src/slices/ui';
import { useDispatch, useSelector } from 'src/store';

import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { ShoeBoxItemProvider } from './shoebox-item-viewer/shoebox-item-viewer.context';
import { ShoeBoxItemViewerActions } from './shoebox-item-viewer/shoebox-item-viewer-actions';

const ShoeBoxItemViewer = dynamic(() => import('src/components/dashboard/shoebox-item-viewer/shoebox-item-viewer.component').then(module => module.ShoeBoxItemViewer), { ssr: false });
const DowntimeAlert = dynamic(() => import('../maintenance/downtime-alert').then(module => module.DowntimeAlert), { ssr: false });
const TimezoneChecker = dynamic(() => import('../user/timezone-checker').then(module => module.TimezoneChecker), { ssr: false });
const ConnectionErrorDialog = dynamic(() => import('../connection-error-dialog/connection-error-dialog.component').then(module => module.ConnectionErrorDialog), { ssr: false });
interface DashboardLayoutProps {
  children?: ReactNode;
  noSideBar?: boolean;
  showGoBackButton?: boolean;
  noSearch?: boolean;
}

// const DashboardLayoutRoot = styled('div')(
//   ({ theme }) => ({
//     position: 'relative',
//     zIndex: 1,
//     display: 'flex',
//     flex: '1 1 auto',
//     backgroundColor: theme.palette.neutral[200],
//     paddingTop: 54
//   })
// );

export const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
  const { children } = props;
  useQueryParamToast();
  useOpenDrawer();
  const { loan } = useActiveLoan();
  // hooks
  const dispatch = useDispatch();
  const { pdftronKey } = useLicenseKeys();
  const theme = useTheme();
  // redux state selectors
  const drawerOpen = useSelector(selectDrawerOpen);
  const connectionError = useSelector(selectConnectionError);

  const handleDrawerToggle = () => {
    dispatch(toggleDrawer(!drawerOpen));
  }

  useEffect(() => {
    dispatch(getJavascriptConstants());
    dispatch(fetch3rdPartyLicenseKeys());
  }, [dispatch]);

  return (
    <StompSessionProvider url={SOCKET_URL}>
      <UploadFormElementContextProvider>
        <MessagesContextProvider>
          <FormElementProvider loan={loan}>
            <ShoeBoxItemProvider>
              <DndProvider>
                <div className='relative z-[1] flex flex-1 bg-white-neutral pt-[54px]'>
                  <Box
                    className='bg-black-10 flex w-full flex-1 flex-col'
                    sx={{
                      paddingLeft: {
                        lg: props.noSideBar ? '0px' : '58px',
                      },
                      transition: theme.transitions.create('padding', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                      }),
                      ...(drawerOpen && {
                        paddingLeft: {
                          lg: props.noSideBar ? '0px' : '256px',
                        },
                        transition: theme.transitions.create('padding', {
                          easing: theme.transitions.easing.easeOut,
                          duration: theme.transitions.duration.enteringScreen
                        })
                      }),
                    }}
                  >
                    <DowntimeAlert />
                    {children}
                  </Box>
                </div>
                <TimezoneChecker />
                {!!connectionError && <ConnectionErrorDialog />}
                <DashboardNavbar
                  sidebarOpen={drawerOpen}
                  noSideBar={props.noSideBar}
                  noSearch={props.noSearch}
                  onToggle={handleDrawerToggle}
                  showGoBackButton={props.showGoBackButton} />
                {!props.noSideBar && <DashboardSidebar
                  onToggle={handleDrawerToggle}
                  open={drawerOpen}
                />}

                <ShoeBoxItemViewer pdftronKey={pdftronKey}>
                  <ShoeBoxItemViewerActions />
                </ShoeBoxItemViewer>
              </DndProvider>
            </ShoeBoxItemProvider>
          </FormElementProvider>
        </MessagesContextProvider>
      </UploadFormElementContextProvider>
    </StompSessionProvider>);
};
