import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Book from '@mui/icons-material/Book';
import LabelIcon from '@mui/icons-material/Label';
import Drawer from '@mui/material/Drawer';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { LoanPhaseCategoryType, RoleLenderGroupLevel, ViewType } from 'src/backend';
import { LoanStageCategory, RoleType } from 'src/constants/loan';
import { PageLabel, Route } from 'src/constants/ui';
import { useActiveLoan } from 'src/hooks/use-active-loan';
import { useLoans } from 'src/hooks/use-loans';
import { useSettings } from 'src/hooks/use-settings';
import { useUser } from 'src/hooks/use-user';
import { Cog } from 'src/icons/cog';
import { hiddenPhasesSelector } from 'src/slices/ui';
import { useSelector } from 'src/store';
import { Loan } from 'src/types/loan';
import { getAssetPath } from 'src/utils/url/get-asset-path';

import { CalendarIcon } from "../../icons/calendar-icon";
import { DashboardSidebarItem } from './dashboard-sidebar-item';
import { DashboardSidebarSection } from './dashboard-sidebar-section';
import { DashboardSidebarShoebox } from './dashboard-sidebar-shoebox';

interface DashboardSidebarProps {
  onToggle: () => void;
  open: boolean;
}

interface Item {
  title: string;
  activeLoan?: boolean;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  roleGroupLevel: RoleLenderGroupLevel;
}

interface Section {
  title: string;
  roleGroupLevel: RoleLenderGroupLevel;
  items: Item[];
}

const homeSectionItem = {
  phaseCategory: LoanStageCategory.ORIGINATION,
  title: PageLabel.Origination,
  path: Route.HOME,
  icon: <Icon className='shrink-0' name="Origination" width={20} height={20} />,
  roleGroupLevel: "LENDER",
}

const lenderSections = [
  {
    title: PageLabel.Home,
    roleGroupLevel: "LENDER",
    items: [
      {
        phaseCategory: LoanStageCategory.LEAD,
        title: PageLabel.Leads,
        path: Route.LOANS_LEAD_HOME,
        icon: <Icon className='shrink-0' name="Filter" width={22} height={22} />,
        inactive: false,
        roleGroupLevel: "LENDER"
      },
      homeSectionItem,
      {
        phaseCategory: LoanStageCategory.PORTFOLIO,
        title: PageLabel.Portfolio,
        path: Route.LOANS_PORTFOLIO_HOME,
        icon: <Icon className='shrink-0' name="PieChart" width={22} height={22} />,
        inactive: false,
        roleGroupLevel: "LENDER"
      },
      {
        phaseCategory: LoanStageCategory.ARCHIVE,
        title: PageLabel.Archive,
        path: Route.ARCHIVE_HOME,
        icon: <Icon className='shrink-0' name="Archive" width={22} height={22} />,
        inactive: false,
        roleGroupLevel: "LENDER"
      },
      {
        title: 'Onboarding',
        path: Route.ADMIN_PAGE,
        icon: <Cog fontSize="medium" />,
        roleGroupLevel: "ADMIN",
        inactive: false
      },
      {
        title: 'Templates',
        path: Route.LIST_V2_TEMPLATE,
        icon: <AccountTreeIcon fontSize="medium" />,
        roleGroupLevel: "ADMIN",
        inactive: false
      },
      {
        title: 'Template Fields',
        path: Route.TEMPLATE_FIELDS,
        icon: <AccountTreeIcon fontSize="medium" />,
        roleGroupLevel: "ADMIN",
        inactive: false
      },
      {
        title: PageLabel.Labels,
        path: Route.ADMIN_LABELS,
        icon: <LabelIcon fontSize="medium" />,
        roleGroupLevel: "ADMIN",
        inactive: false
      },
      {
        title: PageLabel.LearningCenter,
        path: Route.ADMIN_LEARNING_CENTER,
        icon: <Book fontSize="medium" />,
        roleGroupLevel: "ADMIN",
        inactive: false
      },
      {
        title: PageLabel.Maintenance,
        path: Route.ADMIN_MAINTENANCE,
        icon: <CalendarIcon fontSize="medium" />,
        roleGroupLevel: "ADMIN",
        inactive: false
      },
    ]
  }
];

interface GetDashboardSectionsProps {
  canSeeInactivePhases: boolean,
  loansCountByPhaseCategory: Record<LoanPhaseCategoryType, boolean>,
  loan: Loan,
  viewType: ViewType,
  hiddenPhases: string[],
  onBoardingEnabled: boolean
}

export const getDashboardSections = ({
  loansCountByPhaseCategory,
  loan,
  canSeeInactivePhases,
  viewType = "LENDER",
  hiddenPhases }: GetDashboardSectionsProps): Section[] => {
  const items = [];
  items.push(...lenderSections.reduce((acc, section) => {
    const items = section.items
      .filter(item => !hiddenPhases?.includes(item.phaseCategory))
      .filter(item => canSeeInactivePhases ||
        !item.phaseCategory ||
        (item.phaseCategory && loansCountByPhaseCategory[item.phaseCategory]))
      .map(item => ({
        ...item,
        inactive: loan && loan.loanPhase.category !== item.phaseCategory
      }));
    acc.push({
      ...section,
      items
    });
    return acc;
  }, []));

  return items.map(item => ({
    ...item,
    items: item.items.map(childItem => {
      return ({
        ...childItem,
        activeLoan: viewType === "LENDER"
          ? childItem.title.toLowerCase().startsWith(loan?.loanPhase?.category.substring(0, 4).toLowerCase())
          : !!loan?.id
      })
    }),
  }))
};

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onToggle, open } = props;
  const router = useRouter();
  const { loan } = useActiveLoan();
  const { user, viewType, onboardingNeeded, company } = useUser();
  const { version } = useSettings();
  const { allLoans } = useLoans();
  const loansCountByPhaseCategory = allLoans.reduce((acc, loan) => {
    if (loan.loanPhase) {
      acc[loan.loanPhase.category] = true;
    }
    return acc;
  }, {} as Record<LoanPhaseCategoryType, boolean>);
  const lenderName = company?.name ?? allLoans?.[0]?.lender?.name;

  const hiddenPhases = useSelector(hiddenPhasesSelector);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });

  const canSeeInactivePhases = user?.loggedCompanyRole === RoleType.LeadLender || user?.loggedCompanyRole === RoleType.Manager;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sections = useMemo(() => getDashboardSections({
    loansCountByPhaseCategory,
    canSeeInactivePhases,
    loan,
    viewType,
    hiddenPhases,
    onBoardingEnabled: onboardingNeeded
  }),
    [loansCountByPhaseCategory, canSeeInactivePhases, loan, viewType, hiddenPhases, onboardingNeeded]);

  const handlePathChange = useCallback(() => {
    if (!router.isReady) {
      return;
    }

    if (open && !lgUp) {
      onToggle?.();
    }
  }, [lgUp, onToggle, open, router]);

  useEffect(() => {
    const routeChangeStart = () => {
      handlePathChange();
    };
    router.events?.on("routeChangeStart", routeChangeStart);
    return () => {
      router.events?.off("routeChangeStart", routeChangeStart);
    };
  },
    [handlePathChange, router.events]
  );


  const content = (<div className='h-full scrollbar-custom overflow-y-auto overflow-x-hidden bg-black-primary px-2 py-3'>
    <div className='bg-black-primary flex flex-col h-full'>
      <Stack
        data-ui={`${open ? 'active' : 'hidden'}`}
        className='data-active:bg-purple-100 transition-colors rounded-md p-2' row space='sm'>
        <div>
          <Tooltip.Provider>
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Button className="bg-transparent hover:bg-purple-90 px-1 py-1 rounded-sm"
                  onClick={onToggle}
                  size="custom">
                  <Icon className='shrink-0' name="Menu" width={20} height={20} strokeWidth={2} />
                </Button>
              </Tooltip.Trigger>
              <Tooltip.Content>
                <Text size="sm">
                  {open ? 'Close' : 'Open'} Menu
                </Text>
              </Tooltip.Content>
            </Tooltip>
          </Tooltip.Provider>
        </div>
        <Stack
          justify='center'
          className='data-hidden:w-0 w-auto transition-width overflow-hidden'
          data-ui={`${open ? 'active' : 'hidden'}`}
          space='sm'>
          <Text
            size="sm"
            truncate
            weight="medium" variant="white">
            {lenderName}
          </Text>
        </Stack>
      </Stack>
      <div className='flex-grow pt-6 sm:pt-10 xl:pt-16'>
        <Text
          data-ui={!open || !lgUp ? "hidden" : ""}
          size="xs"
          className="data-hidden:opacity-0 pl-3"
          variant="secondary">
          WORKSPACE
        </Text>
        {["LENDER", "ADMIN"].includes(viewType) && <ul className='pt-3'>
          <DashboardSidebarItem
            disabled
            depth={0}
            hidden
            title="Loans"
            icon={<Icon className='shrink-0' name="Handbag" width={20} height={20} />}
            sidebarOpen={open || !lgUp}
            path={Route.HOME}

          >
            {sections.map((section) => (
              <DashboardSidebarSection
                depth={1}
                key={section.title}
                sidebarOpen={open || !lgUp}
                path={router.asPath}
                {...section}
              />
            ))}
          </DashboardSidebarItem>
        </ul>}
        {!["LENDER", "ADMIN"].includes(viewType) && sections.map((section) => (
          <DashboardSidebarSection
            key={section.title}
            sidebarOpen={open || !lgUp}
            path={router.asPath}
            {...section}
          />
        ))}
      </div>
      <DashboardSidebarShoebox
        size={open ? "default" : "small"} />
      <Stack
        row
        data-ui={`${open ? 'active' : 'hidden'}`}
        className={`space-x-2 w-full data-hidden:pl-2 h-10 overflow-hidden mt-14 data-hidden:space-x-0 transition-all will-change-auto`}>
        <div className='shrink-0'>
          <Image
            src={getAssetPath('static/android-icon-72x72.png')}
            alt="mysherpas"
            className='rounded-full shrink-0'
            width='32'
            height='32'
            layout='fixed'
          />
        </div>
        <Stack space="sm" className='data-hidden:w-0 w-full overflow-hidden transition-all will-change-auto' data-ui={open ? "active" : 'hidden'}>
          <Text variant="white" size="xs" truncate>
            Powered by mysherpas
          </Text>
          <Text variant="white" size="xs" weight="medium" truncate>
            {version}
          </Text>
        </Stack>
      </Stack>
    </div>
  </div>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        data-testid="dashboard-sidebar-drawer"
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: 0,
            transformOrigin: 'left',
            color: '#FFFFFF',
            width: '58px',
            transition: theme.transitions.create(['width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            }),
            ...(open && {
              width: '256px',
              transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
              })
            })
          })
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onToggle}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 256
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

